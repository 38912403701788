import React from 'react';
import styles from './home.module.scss';

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            width: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        const landscapeMode = this.state.height < this.state.width;
        return (

            <div className={landscapeMode ? styles.horizontalContainer : styles.verticalContainer}>
                <div className={landscapeMode ? styles.firstLine : styles.firstLinePortrait} >We design and develop</div>
                <div className={landscapeMode ? styles.secondLine : styles.secondLinePortrait}  >custom high-quality</div>
                <div className={landscapeMode ? styles.firstLine : styles.firstLinePortrait}  >software &amp; experiences</div>
            </div>
        );
    }


}

export { Home };





