import React from 'react';
import styles from './services.module.scss';

class Services extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            width: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    getTitle(name1, name2) {
        const landscapeMode = this.state.height < this.state.width;
        if (landscapeMode) {
            return <div>
                <div className={landscapeMode ? styles.name : styles.namePortrait}>
                    {name1}
                </div>
                <div className={landscapeMode ? styles.name : styles.namePortrait}>
                    {name2}
                </div>
            </div>
        } else {
            return <div className={landscapeMode ? styles.name : styles.namePortrait}>
                {name1} {" " + name2}
            </div>
        };
    }


    render() {
        const landscapeMode = this.state.height < this.state.width;
        var titleMobile = this.getTitle("Mobile", "development");
        var titleWeb = this.getTitle("Web", "development");
        var titleUx = this.getTitle("UI & UX", "design");
        var mobileDevelopment = (
            <div className={landscapeMode ? styles.elementContainerMobile : styles.elementContainerPortraitMobile}>
                {titleMobile}
                <div className={landscapeMode ? styles.description : styles.descriptionPortrait}>
                Get solutions in half the time without breaking the bank.
We build captivating yet usable apps with a native look and feel.
The team has years of experience developing with Google’s technology: Flutter.
Our technology is compatible with both iOS &amp; Android.
                </div>
            </div>
        );
        var webDevelopment = (
            <div className={landscapeMode ? styles.elementContainerWeb : styles.elementContainerPortraitWeb}>
                {titleWeb}
                <div className={landscapeMode ? styles.description : styles.descriptionPortrait}>
                    We create tailor-made websites. No matter if you are a small business, entrepreneur or a multi-national company, we will develop a product that suits YOUR needs. We use the latest techonologies such as React, Node.js, Ruby on Rails and Flutter.
                </div>
            </div>

        );
        var uxuiDevelopment = (
            <div className={landscapeMode ? styles.elementContainerUxui : styles.elementContainerPortraitUxui}>
                {titleUx}
                <div className={landscapeMode ? styles.descriptionUi : styles.descriptionUiPortrait}>
                    Design is no longer about the wow factor. UI/UX Design is about providing personalization, simplicity, engagement, and accessibility. The devil is in the details. That’s why we take a user-centric design approach to ensure the
customer journey is as smooth as possible.
                </div>
            </div>
        );
        return (
            <div className={landscapeMode ? styles.row : styles.column}>
                <div className={landscapeMode ? styles.thirdColumn : styles.thirdRow} >{mobileDevelopment}</div>
                <div className={landscapeMode ? styles.thirdColumn : styles.thirdRow}  >{webDevelopment}</div>
                <div className={landscapeMode ? styles.thirdColumn : styles.thirdRow}  >{uxuiDevelopment}</div>
            </div>
        );
    }


}

export { Services };





