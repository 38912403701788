import React from 'react';
import TrackVisibility from 'react-on-screen';

import { Home } from '../home/home';
import { Services } from '../services/services';

import menu from '../assets/img/menu.svg';
import logo from '../assets/img/logo.png';
import close from '../assets/img/close.svg';
import styles from './mainScreen.module.scss';
import { Process } from '../process/process';
import { About } from '../about/about';
import { Contact } from '../contact';
import { Work } from '../work';

const HOME = 1;
const SERVICES = 2;
const PROCESS = 3;
const WORK = 4;
const ABOUT = 5;
const CONTACT = 6;

class MainScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPage: HOME,
            openedMenu: false,
            height: 0,
            width: 0
        };
        this.myRef = [];
        this.toggleMenu = this.toggleMenu.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.setPage = this.setPage.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    home = ({ isVisible }) => {
        if (isVisible) this.setState({ selectedPage: HOME });
        return <div ref={(ref) => { this.myRef[1] = ref }} className={styles.home} >
            <Home></Home>
        </div>;
    }

    services = ({ isVisible }) => {
        if (isVisible) this.setState({ selectedPage: SERVICES });
        return <div ref={(ref) => { this.myRef[2] = ref }} className={styles.services}>
            <Services></Services>
        </div>;
    }

    process = ({ isVisible }) => {
        if (isVisible) this.setState({ selectedPage: PROCESS });
        return <div ref={(ref) => { this.myRef[3] = ref }} className={styles.process}>
            <Process></Process>
        </div>;
    }

    work = ({ isVisible }) => {
        if (isVisible) this.setState({ selectedPage: WORK });
        return <div ref={(ref) => { this.myRef[4] = ref }} className={styles.work}>
            <Work></Work>
        </div>;
    }

    about = ({ isVisible }) => {
        if (isVisible) this.setState({ selectedPage: ABOUT });
        return <div ref={(ref) => { this.myRef[5] = ref }} className={styles.about}>
            <About></About>
        </div>;
    }

    contact = ({ isVisible }) => {
        if (isVisible) this.setState({ selectedPage: CONTACT });
        const landscapeMode = this.state.height < this.state.width;
        return <div ref={(ref) => { this.myRef[6] = ref }} className={styles.contact}>

            <Contact></Contact>
        </div>;
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    async toggleMenu() {
        await this.setState({
            openedMenu: !this.state.openedMenu
        });
        console.log(this.state.openedMenu)
    }

    goToPage(page) {
        this.setState({
            selectedPage: page
        });
        this.myRef[page].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    async goToPageFromCondensed(page) {
        await this.setState({
            openedMenu: false,
            selectedPage: page
        });
        if (this.state.openedMenu == false) this.myRef[page].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    setPage(page) {
        this.setState({
            selectedPage: page
        });
    }

    render() {
        const landscapeMode = this.state.height < this.state.width;
        var navbarRight = this.state.width > 1144 ?
            (
                <div className={styles.rightFull}>
                    <div onClick={() => this.goToPage(HOME)} className={this.state.selectedPage === HOME ? styles.sectionTitleHomeSelected : styles.sectionTitleHome}>HOME</div>
                    <div onClick={() => this.goToPage(SERVICES)} className={this.state.selectedPage === SERVICES ? styles.sectionTitleServicesSelected : styles.sectionTitleServices}>SERVICES</div>
                    <div onClick={() => this.goToPage(PROCESS)} className={this.state.selectedPage === PROCESS ? styles.sectionTitleProcessSelected : styles.sectionTitleProcess}>PROCESS</div>
                    <div onClick={() => this.goToPage(WORK)} className={this.state.selectedPage === WORK ? styles.sectionTitleWorkSelected : styles.sectionTitleWork}>WORK</div>
                    <div onClick={() => this.goToPage(ABOUT)} className={this.state.selectedPage === ABOUT ? styles.sectionTitleAboutSelected : styles.sectionTitleAbout}>ABOUT</div>
                    <div onClick={() => this.goToPage(CONTACT)} className={this.state.selectedPage === CONTACT ? styles.sectionTitleContactSelected : styles.sectionTitleContact}>CONTACT</div>
                </div>
            ) : (
                <div className={styles.rightCondensed}>
                    <img src={menu} className={styles.menuIcon} onClick={() => this.toggleMenu()}></img>
                </div>

            );
        return (<div className={styles.ok}>
            {this.state.openedMenu === true &&
                <div className={styles.menu}>
                    <div className={styles.closeRow} onClick={() => this.toggleMenu()} ><img className={styles.close} src={close}></img></div>
                    <div onClick={() => this.goToPageFromCondensed(HOME)} className={this.state.selectedPage === HOME ? styles.sectionCondensedTitleHomeSelected : styles.sectionCondensedTitleHome}>HOME</div>
                    <div onClick={() => this.goToPageFromCondensed(SERVICES)} className={this.state.selectedPage === SERVICES ? styles.sectionCondensedTitleServicesSelected : styles.sectionCondensedTitleServices}>SERVICES</div>
                    <div onClick={() => this.goToPageFromCondensed(PROCESS)} className={this.state.selectedPage === PROCESS ? styles.sectionCondensedTitleProcessSelected : styles.sectionCondensedTitleProcess}>PROCESS</div>
                    <div onClick={() => this.goToPageFromCondensed(WORK)} className={this.state.selectedPage === WORK ? styles.sectionCondensedTitleWorkSelected : styles.sectionCondensedTitleWork}>WORK</div>
                    <div onClick={() => this.goToPageFromCondensed(ABOUT)} className={this.state.selectedPage === ABOUT ? styles.sectionCondensedTitleAboutSelected : styles.sectionCondensedTitleAbout}>ABOUT</div>
                    <div onClick={() => this.goToPageFromCondensed(CONTACT)} className={this.state.selectedPage === CONTACT ? styles.sectionCondensedTitleContactSelected : styles.sectionCondensedTitleContact}>CONTACT</div>
                </div>}
            {this.state.openedMenu === false &&
                <div className={styles.mainContainer}>
                    <div className={styles.navbar}>
                        <div className={styles.left}>
                            <img onClick={() => this.goToPage(HOME)} className={styles.logo} src={logo}></img>
                            {landscapeMode}
                        </div>
                        {navbarRight}
                    </div>
                    <div className={styles.sidebar}>
                        <div onClick={() => this.goToPage(HOME)} className={this.state.selectedPage === HOME ? styles.sidebarHomeSelected : styles.sidebarHome}></div>
                        <div onClick={() => this.goToPage(SERVICES)} className={this.state.selectedPage === SERVICES ? styles.sidebarServicesSelected : styles.sidebarServices}></div>
                        <div onClick={() => this.goToPage(PROCESS)} className={this.state.selectedPage === PROCESS ? styles.sidebarProcessSelected : styles.sidebarProcess}></div>
                        <div onClick={() => this.goToPage(WORK)} className={this.state.selectedPage === WORK ? styles.sidebarWorkSelected : styles.sidebarWork}></div>
                        <div onClick={() => this.goToPage(ABOUT)} className={this.state.selectedPage === ABOUT ? styles.sidebarAboutSelected : styles.sidebarAbout}></div>

                        <div onClick={() => this.goToPage(CONTACT)} className={this.state.selectedPage === CONTACT ? styles.sidebarContactSelected : styles.sidebarContact}></div>
                    </div>
                    <TrackVisibility offset={200}>
                        {this.home}
                    </TrackVisibility>
                    <TrackVisibility offset={200} >
                        {this.services}
                    </TrackVisibility>
                    <TrackVisibility offset={200} >
                        {this.process}
                    </TrackVisibility>
                    <TrackVisibility offset={200} >
                        {this.work}
                    </TrackVisibility>

                    <TrackVisibility offset={200}>
                        {this.about}
                    </TrackVisibility>

                    <TrackVisibility offset={100}>
                        {this.contact}
                    </TrackVisibility></div>}
        </div>

        );
    }


}

export { MainScreen };





