import React from 'react';
import styles from './process.module.scss';

class Process extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            width: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    step(numberClassLandscape, numberClass, number, title, description) {
        const landscapeMode = this.state.height < this.state.width;
        if (landscapeMode) {
            return <div className={styles.container}>
                <div className={styles.elementRowLandscape}>
                    <div className={styles.numberColumn}>
                        <div className={numberClassLandscape}> {number}</div>
                    </div>
                    <div className={styles.contentColumn}>
                        <div className={styles.titleLandscape}>{title}</div>
                    </div>
                </div>
                <div className={styles.elementRowLandscape}>
                    <div className={styles.numberColumn}>
                    </div>

                    <div className={styles.contentColumn}>
                        <div className={styles.descriptionLandscape}>{description}</div>
                    </div>
                </div>
            </div >
        } else {
            return <div className={number === 3 ? styles.portraitContainerThree : styles.portraitContainer}>
                <div className={styles.titleRowPortrait}>
                    <div className={numberClass}>{number}</div>
                    <div className={styles.titlePortrait}>{title}</div>
                </div>
                <div className={styles.descriptionRowPortrait}>
                    <div className={styles.description}>{description}</div>
                </div>
            </div>
        }
    }

    render() {
        const landscapeMode = this.state.height < this.state.width;
        const stepOne = this.step(styles.numberLandscapeBlue, styles.numberBlue, 1, "Empathy & Inception", "The first step is getting to know you and your business, understanding the brand, technology and your customer's demands. This will give us the insights needed to create an accurate solution to your problem. ");
        const stepTwo = this.step(styles.numberLandscapeYellow, styles.numberYellow, 2, "Design & Development", "Solutions are designed and developed using an agile, iterative process with 2-week sprints, prioritizing features by the value they add to your business. We move fast in order to get continuous feedback and validate the product.");
        const stepThree = this.step(styles.numberLandscapeGreen, styles.numberGreen, 3, "Launch", "Once the requirements are met and the solution is in tip-top shape, we launch it so it can start adding value.");
        const stepFour = this.step(styles.numberLandscapeRed, styles.numberRed, 4, "Development & Support", "As the product enters the market, we offer support & analyse its performance in order to develop and deploy new features. In this way, we optimize the solution and keep adding value as it grows.");
        return (
            <div className={styles.column}>
                <div className={landscapeMode ? styles.halfRow : styles.halfColumn}>
                    {stepOne}
                    {stepTwo}
                </div>
                <div className={landscapeMode ? styles.halfRow : styles.halfColumn}>
                    {stepThree}
                    {stepFour}
                </div>
            </div>
        );
    }


}

export { Process };





