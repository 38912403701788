import React from 'react';
import styles from './contact.module.scss';
import * as EmailValidator from 'email-validator';
import Loader from 'react-loader-spinner'

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            width: 0,
            email: '',
            name: '',
            message: '',
            loading: false
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        const script = document.createElement("script");

        script.src = "https://widget.clutch.co/static/js/widget.js";
        script.async = true;

        document.body.appendChild(script);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleNameChange(event) {
        this.setState({ error: "", success: "", name: event.target.value });
    }

    handleEmailChange(event) {
        this.setState({ error: "", success: "", email: event.target.value });
    }

    handleMessageChange(event) {
        this.setState({ error: "", success: "", message: event.target.value });
    }

    handleSubmit(event) {
        if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
            this.setState({
                error: "ALL FIELDS ARE REQUIRED"
            });
        } else {
            var ok = EmailValidator.validate(this.state.email);
            if (ok === false) {
                this.setState({
                    error: "PLEASE ENTER A VALID EMAIL"
                });
            } else {
                this.setState({
                    error: "",
                    loading: true
                });
                fetch('https://smartup-web-backend.herokuapp.com/email', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 'email': this.state.email, 'name': this.state.name, 'message': this.state.message })
                }).then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            success: "SUCCESS",
                            message: "",
                            email: "",
                            name: "",
                            loading: false
                        })
                    } else {
                        this.setState({
                            loading: false,
                            error: "THERE WAS AN ERROR :( PLEASE TRY AGAIN LATER"
                        })
                    }
                }).catch((er) => {
                    this.setState({
                        loading: false,
                        error: "THERE WAS AN ERROR :(, PLEASE TRY AGAIN LATER"
                    })
                });
            }
        }
        event.preventDefault();
    }

    render() {
        const landscapeMode = this.state.height < this.state.width;
        var formNameEmail = landscapeMode ? (
            <div>
                <div className={styles.formRow}>
                    <input className={styles.formName} type="text" placeholder="Name" value={this.state.name} onChange={this.handleNameChange} />
                    <input className={styles.formName} type="text" placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} />
                </div>

                <textarea className={styles.formMessage} rows="6" type="text" placeholder="What can we do for you?" value={this.state.message} onChange={this.handleMessageChange}></textarea>
                <div className={styles.formRow}>
                    {this.state.error && <div className={styles.error}>{this.state.error}</div>}
                    {this.state.success && <div className={styles.success}>WE WILL GET IN TOUCH WITH YOU ASAP!</div>}
                    {this.state.loading === true && <Loader
                        type="ThreeDots"
                        color="#FA9917"
                        height={100}
                        width={100}
                        timeout={3000}
                    />}
                    {!this.state.success && !this.state.error && this.state.loading === false && <div className={styles.success}></div>}
                    <input className={styles.buttonHalf} type="submit" value="SEND" />
                </div>
            </div>
        ) : (
            <div className={styles.formColumn}>
                <input className={styles.formNamePortrait} type="text" placeholder="Name" value={this.state.value} onChange={this.handleNameChange} />
                <input className={styles.formNamePortrait} type="text" placeholder="Email" value={this.state.value} onChange={this.handleEmailChange} />
                <textarea className={styles.formMessagePortrait} type="text" rows="6" placeholder="What can we do for you?" value={this.state.message} onChange={this.handleMessageChange}></textarea>
                {this.state.error && <div className={styles.errorPortrait}>{this.state.error}</div>}
                {this.state.success && <div className={styles.successPortrait}>WE WILL GET IN TOUCH WITH YOU ASAP!</div>}
                {this.state.loading === true && <Loader
                    type="ThreeDots"
                    color="#FA9917"
                    height={100}
                    width={100}
                    timeout={3000}
                />}
                {!this.state.success && !this.state.error && this.state.loading === false && <div className={styles.successPortrait}></div>}
                <input className={styles.buttonFull} type="submit" value="SEND" />
            </div>);
        return (
            <div className={styles.column}>
                <div className={landscapeMode ? styles.row : styles.column}>
                    <div className={landscapeMode ? styles.halfHorizontal : styles.contentVertical}>
                        <div className={landscapeMode ? styles.title : styles.titlePortrait}>Have an idea?</div>
                        <div className={landscapeMode ? styles.title : styles.titlePortrait}>Let's talk!</div>
                        <div className={landscapeMode ? styles.descriptionTop : styles.descriptionPortrait}> hello@smartupsoftware.com</div>
                        <div className={landscapeMode ? styles.description : styles.descriptionPortrait}>(598) 99 751 737</div>
                    </div>
                    <div className={landscapeMode ? styles.formHorizontal : styles.formVertical}>
                        <form onSubmit={this.handleSubmit}>
                            <div className={styles.formColumn}>
                                {formNameEmail}
                            </div>
                        </form>
                    </div>

                </div> </div>
        );
    }


}

export { Contact };





