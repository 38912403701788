import React from 'react';
import styles from './about.module.scss';
import map from '../assets/img/map.png'

class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            width: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        const landscapeMode = this.state.height < this.state.width;
        return (
            <div className={landscapeMode ? styles.row : styles.column}>
                <div className={landscapeMode ? styles.halfHorizontal : styles.contentVertical}>
                    <div className={landscapeMode ? styles.title : styles.titlePortrait}>Dreamers &amp; Doers</div>
                    <div className={landscapeMode ? styles.description : styles.descriptionPortrait}> We are a team of smart, creative and passionate engineers. We are innovators, builders, strategists, determined on delivering high quality solutions for our clients. We create what they need, when they need it.</div>
                    <div className={landscapeMode ? styles.description : styles.descriptionPortrait}>Based in Uruguay, we are located in a strategic timezone, EST+1 which allows us to collaborate with US clients in real-time throughout the working day.</div>
                    <div className={landscapeMode ? styles.clutchWeb : styles.clutchMobile}>
                        <div class="clutch-widget" data-url="https://widget.clutch.co" data-height='375' data-widget-type="12" data-width="70vw" data-clutchcompany-id="1817490"></div>
                    </div>
                </div>
                {landscapeMode ? <div className={landscapeMode ? styles.mapHorizontal : styles.mapVertical}>
                    <img src={map} className={styles.map}></img>
                </div> : <div></div>}
            </div>
        );
    }


}

export { About };





