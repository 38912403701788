import React from 'react';
import './App.css';
import { MainScreen } from './mainScreen/mainScreen';
import GA4React from "ga-4-react";
// const ga4react = new GA4React("G-RRJF78G68P");
// ga4react.initialize().then().catch()
// import ReactGA from 'react-ga';

// const trackingId = "UA-147483332-1"; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId);

try {
  setTimeout(_ => {
    const ga4react = new GA4React("G-RRJF78G68P");
    ga4react.initialize().catch(err => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}

function App() {
  return (
    <MainScreen />
  );
}

export default App;
