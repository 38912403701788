import React from 'react';
import styles from './work.module.scss';
import firebase from '../assets/img/firebase.png';
import nodejs from '../assets/img/node.png';
import flutter from '../assets/img/flutter.png';
import rails from '../assets/img/rails.png';
import react from '../assets/img/react.png';
import berrinche from '../assets/img/berrincheScreen.png';
import dealxu from '../assets/img/dealxuScreen.png';
import absolut from '../assets/img/absolutScreen.png';
import absolutVideo from '../assets/img/Absolut.mp4'

import ItemsCarousel from 'react-items-carousel';


const BERRINCHE = 1;
const ABSOLUT = 2;
const DEALXU = 3;

class Work extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            width: 0,
            selectedProject: BERRINCHE,
            activeItemIndex: 0
        }
        var children = [];
        this.pictures= [dealxu, absolut, absolutVideo, berrinche];
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.setProject = this.setProject.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.pictures.forEach((picture) => {
            const img = new Image();
            img.src = picture.fileName;
        });
        this.children = [
            this.getVerticalPhoneDetails(berrinche, "Berrinche", "Berrinche is our very own social project. An app created to help parents and therapists of those with ASD (Autism Spectre Disorder). Its main purpose is to gather information about their disruptive conduct in order to analyse them, discover their causes and figure out how to treat & modify them. Berrinche aims to create an inclusive environment where everyone around those with ASD is aware and able to help. Available in both App Store & Play Store."),
            this.getVerticalPhoneDetails(berrinche, "Berrinche", "Berrinche is our very own social project. An app created to help parents and therapists of those with ASD (Autism Spectre Disorder). Its main purpose is to gather information about their disruptive conduct in order to analyse them, discover their causes and figure out how to treat & modify them. Berrinche aims to create an inclusive environment where everyone around those with ASD is aware and able to help. Available in both App Store & Play Store."),
            this.getVerticalPhoneDetails(berrinche, "Berrinche", "Berrinche is our very own social project. An app created to help parents and therapists of those with ASD (Autism Spectre Disorder). Its main purpose is to gather information about their disruptive conduct in order to analyse them, discover their causes and figure out how to treat & modify them. Berrinche aims to create an inclusiveenvironment where everyone around those with ASD is aware and able to help. Available in both App Store & Play Store."),
            this.getVerticalPhoneDetails(dealxu, "DealXu", "We built the DealXu app for one of our clients. DealXu helps users find groceries and other essential products in nearby stores. The app also allows users to update product availability using easy upvote/downvote buttons in the app. This helps fellow shoppers, neighbors and senior citizens to easily find their day-to-day essentials."),
            this.getVerticalIpadDetails(absolut, "Absolut", "This game was created for a product launch for Absolut Vodka. The Trivia quiz challenges your knowledge of the world-famous brand. Our games are a great way to promote your business and keep your guests happy and entertained. Built for all mobile devices.")

        ];
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    setProject(project) {
        this.setState({ selectedProject: project });
    }

    getVerticalPhoneDetails(image, title, description) {
        return <div className={styles.projectVerticalColumn}>
            <div className={styles.projectVerticalImg}>
                <img src={image} className={styles.phoneScreenVerticalImg}></img>
            </div>
            <div className={styles.projectVerticalData}>
                <div className={styles.titlePortrait}>
                    {title}
                </div>
                <div className={styles.descriptionPortrait}>
                    {description}
                </div>
            </div>

        </div>;
    }

    getVerticalIpadDetails(image, title, description) {
        return <div className={styles.projectVerticalColumn}>
            <div className={styles.videoVertical}>
                <video className={styles.projectIpadImgVertical} controls>
                    <source src={absolutVideo} type="video/mp4" />

                        Your browser does not support the video tag.
                    </video>
            </div>
            <div className={styles.projectVerticalData}>
                <div className={styles.titlePortrait}>
                    {title}
                </div>
                <div className={styles.descriptionPortrait}>
                    {description}
                </div>
            </div>

        </div>;
    }

    getPhoneDetails(image, title, description) {
        return <div className={styles.projectColumn}>
            <div className={styles.projectData}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
            </div>
            <div className={styles.projectImg}>
                <img src={image} className={styles.phoneScreenImg}></img>
            </div>
        </div>;
    }

    getIPadDetails(image, title, description) {
        return <div className={styles.projectIpadColumn}>
            <div className={styles.video}>
                <video className={styles.projectIpadImg} controls>
                    <source src={absolutVideo} type="video/mp4" />

                        Your browser does not support the video tag.
                    </video>
            </div>
            <div className={styles.projectIpadData}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
            </div>

        </div>;
    }


    render() {
        const landscapeMode = this.state.height < this.state.width;
        var detailBerrinche = this.getPhoneDetails(berrinche, "Berrinche", "Berrinche is our very own social project. An app created to help parents and therapists of those with ASD (Autism Spectre Disorder). Its main purpose is to gather information about their disruptive conduct in order to analyse them, discover their causes and figure out how to treat & modify them. Berrinche aims to create an inclusive environment where everyone around those with ASD is aware and able to help. Available in both App Store & Play Store.");
        var detailDealXu = this.getPhoneDetails(dealxu, "DealXu", "We built the DealXu app for one of our clients. DealXu helps users find groceries and other essential products in nearby stores. The app also allows users to update product availability using easy upvote/downvote buttons in the app. This helps fellow shoppers, neighbors and senior citizens to easily find their day-to-day essentials.");
        var detailAbsolut = this.getIPadDetails(absolut, "Absolut", "This game was created for a product launch for Absolut Vodka. The Trivia quiz challenges your knowledge of the world-famous brand. Our games are a great way to promote your business and keep your guests happy and entertained. Built for all mobile devices.");
        var show;
        if (this.state.selectedProject === BERRINCHE) {
            show = detailBerrinche;
        } else if (this.state.selectedProject === DEALXU) {
            show = detailDealXu;
        } else {
            show = detailAbsolut
        }

        return (
            <div className={styles.column}>
                {landscapeMode === true && <div>
                    <div className={styles.contentRow}>
                        <div className={styles.listColumn}>
                            <div className={styles.listElementBerrinche} onClick={() => this.setProject(BERRINCHE)}></div>
                            <div className={styles.listElementAbsolut} onClick={() => this.setProject(ABSOLUT)}></div>
                            <div className={styles.listElementDealxu} onClick={() => this.setProject(DEALXU)}></div>
                        </div>

                        {show}

                    </div>
                    <div className={styles.technologyRow}>
                        <img className={styles.technologyIcon} src={firebase}></img>
                        <img className={styles.technologyIcon} src={nodejs}></img>
                        <img className={styles.technologyIcon} src={flutter}></img>
                        <img className={styles.technologyIcon} src={rails}></img>
                        <img className={styles.technologyIcon} src={react}></img>
                    </div>
                </div>}
                {landscapeMode === false && <div>
                    <div className={styles.sliderRow}>
                        <ItemsCarousel
                            className={styles.itemsCarousel}
                            infiniteLoop={false}
                            gutter={5}
                            activePosition={'left'}
                            chevronWidth={60}
                            disableSwipe={false}
                            alwaysShowChevrons={false}
                            numberOfCards={5}
                            slidesToScroll={1}
                            outsideChevron={true}
                            showSlither={false}
                            firstAndLastGutter={false}
                            activeItemIndex={this.state.activeItemIndex}
                            requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                            rightChevron={
                                <div className={styles.button}>

                                </div>
                            }
                            leftChevron={
                                <div className={styles.button}>

                                </div>
                            }
                            chevronWidth={50}


                        >
                            {this.children}
                        </ItemsCarousel>
                    </div>
                    <div className={styles.techPortraitRow}>
                        <div className={styles.techPortraitContainer}> <img className={styles.technologyPortraitIcon} src={firebase}></img></div>
                        <div className={styles.techPortraitContainer}>  <img className={styles.technologyPortraitIcon} src={nodejs}></img></div>
                        <div className={styles.techPortraitContainer}>  <img className={styles.technologyPortraitIcon} src={flutter}></img></div>

                    </div>
                    <div className={styles.techPortraitRow}>
                        <div className={styles.techPortraitContainer}> <img className={styles.technologyPortraitIcon} src={rails}></img></div>
                        <div className={styles.techPortraitContainer}> <img className={styles.technologyPortraitIcon} src={react}></img></div>
                    </div>
                </div>}
            </div>

        );
    }


}

export { Work };





